import { instance } from '@/utils/http.js'
import getFile from '@/utils/file'
import getMd5Form from '@/utils/getMd5Form.js'


// 上传文件
export const uploadFile = function (data) {
  return instance({
    url: '/api/v1/papers/upload_answer_file',
    method: 'post',
    Headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 上传文件方法 二次封装 集成选择文件，上传文件
export const uploadImageAuto = async function (limit = 20, fileType = "*") {
  const res = await getFile({ limit, fileType })
  let formData = new FormData()
  formData.append('filename', 'image')
  formData.append('image', res)
  formData.append('sign', getMd5Form({ filename: 'image' }))
  const data = await uploadFile(formData)
  return data
}
