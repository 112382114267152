<template>
  <div class="card_warp">
    <TopCard text="答题卡" />
    <p>
      答题情况：
    </p>
    <div class="cards">
      <div class="card"
           @click="checkTopic(item,index)"
           v-for="(item,index) in info.volist"
           :key="index">
        <span :class="getClass(item)">{{item.user_answer||index + 1}}</span>
      </div>
    </div>
    <p>图片: </p>
    <div class="img_warp">
      <div class="image"
           v-for="item in images"
           :key="item">
        <a-image :src="addAllHeader(item)"
                 width="120rem"
                 height="120rem">
        </a-image>
      </div>
    </div>
  </div>
  <!-- <div class="btn_warp"
         @click="submitAll">
      提交
    </div> -->
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { checkArr as globalCheckArr } from '@/init/index.js'
import { message } from 'ant-design-vue'
import { uploadImageAuto } from '@/api/upload.js'
import { addHeader,addAllHeader } from '@/init/index.js'
import TopCard from '@/views/components/top_card/index.vue'


let router = useRouter()
let route = useRoute()

let info = reactive({})
let images = ref([])
let checkArr = globalCheckArr
const submitDialogRef = ref(null)


onMounted(() => {
  let paper = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsis'))
  paper.volist = paper.volist.map(item => {
    if (item.user_answer) {
      item.answer = item.user_answer.split(',')
    }
    return item
  })
  for (const key in paper) {
    info[key] = paper[key]
  }
  images.value = paper.images
 
})

const getClass = (item) => {
  // check_id -1未批改  0自动批改 <> -1已批改
  if (item.auto_judge || item.check_id != -1) {
    // 如果已经批改
    if (Number(item.user_score) == Number(item.question_score)) {
      return 'active'
    } else if (Number(item.user_score) != 0) {
      return 'yiban'
    }
    return 'err'
  } else {
    return ''
  }
}



const checkTopic = (item, index) => {
  window.sessionStorage.setItem('analysisCardIndex', `${index}`)
  window.sessionStorage.setItem('images', JSON.stringify(images.value || []))
  router.go(-1)
}

</script>

<style lang="scss" scoped>
p {
  font-size: 20rem;
}
.card_warp {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  p {
    margin-top: 27rem;
    text-indent: 40rem;
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
  }
  .cards {
    margin-left: 40rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 20rem;
    .card {
      width: 120rem;
      height: 120rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      font-weight: bold;
      font-size: 32rem;
      margin-right: 24rem;
      margin-bottom: 30rem;
      background: #f6f6f6;
      border-radius: 10rem;
      cursor: pointer;
    }
  }
  .img_warp {
    display: flex;
    margin-left: 40rem;
    margin-top: 40rem;
    .image {
      position: relative;
      width: 120rem;
      height: 120rem;
      margin-right: 24rem;
      justify-content: center;
      .close {
        position: absolute;
        right: -5px;
        top: -5px;
        background: red;
        color: white;
        width: 30rem;
        height: 30rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        z-index: 19;
      }
    }
  }
}

.active {
  background: #ecfcf5 !important;
  color: #0ec3b5;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.err {
  background: #fcecec !important;
  color: #ff6f6f;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.yiban {
  background: #fcf2ec !important;
  color: #ff916f;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>